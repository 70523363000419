var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { value: _vm.value, persistent: "", width: "1500", fullscreen: "" }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-container",
            { staticClass: "ma-0", attrs: { fluid: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", flat: "", color: "#1C3144" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:value", false)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isFeatureSelected
                            ? _vm.selectedFeature.adName +
                                " - " +
                                _vm.selectedFeature.featureType.name
                            : "Ad Feature Management"
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-toolbar",
                { attrs: { color: "#fafafa" } },
                [
                  !_vm.featureView
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "#0c67a5", text: "", small: "" },
                          on: { click: _vm.getAdFeatures }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-chevron-left")
                          ]),
                          _vm._v(" Back to Features ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _vm.featureView || _vm.selectedFeatures.length
                    ? _c(
                        "v-btn-toggle",
                        { attrs: { mandatory: "" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "white--text",
                                              attrs: {
                                                color: !_vm.isExpanded
                                                  ? "green"
                                                  : "red",
                                                height: "40",
                                                disabled: !_vm.isExpanded
                                              },
                                              on: {
                                                click: function($event) {
                                                  ;(_vm.isExpanded = false),
                                                    _vm.collapseAll()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [
                                                _vm._v(
                                                  "mdi-collapse-all-outline"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                431548771
                              )
                            },
                            [_c("span", [_vm._v("Collapse Rows")])]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "white--text",
                                              attrs: {
                                                color: _vm.isExpanded
                                                  ? "red"
                                                  : "green",
                                                height: "40",
                                                disabled: _vm.isExpanded
                                              },
                                              on: {
                                                click: function($event) {
                                                  ;(_vm.isExpanded = true),
                                                    _vm.expandAll()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("mdi-expand-all-outline")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3810758504
                              )
                            },
                            [_c("span", [_vm._v("Expand Rows")])]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.featureView
                    ? _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            staticClass: "mt-6",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Sort By Asc",
                              items: _vm.sortOptions,
                              "menu-props": { bottom: true, offsetY: true },
                              "item-text": "label",
                              "item-value": "value",
                              "prepend-inner-icon": "mdi-sort-ascending"
                            },
                            on: { change: _vm.changeSort }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider", {
                    staticClass: "mx-4",
                    attrs: { vertical: "", inset: "" }
                  }),
                  _vm.featureView
                    ? _c(
                        "v-btn-toggle",
                        {
                          staticClass: "btnGroup",
                          attrs: { dense: "", mandatory: "" },
                          model: {
                            value: _vm.filterToggle,
                            callback: function($$v) {
                              _vm.filterToggle = $$v
                            },
                            expression: "filterToggle"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              class:
                                _vm.filterToggle === 0
                                  ? "white--text"
                                  : "black--text",
                              staticStyle: {
                                "border-top-left-radius": "3px",
                                "border-bottom-left-radius": "3px"
                              },
                              attrs: {
                                color:
                                  _vm.filterToggle === 0
                                    ? "#0c67a5"
                                    : "#e6e6e6",
                                height: "38"
                              }
                            },
                            [_c("small", [_vm._v("Manufacturer/Description")])]
                          ),
                          _c(
                            "v-btn",
                            {
                              class:
                                _vm.filterToggle === 1
                                  ? "white--text"
                                  : "black--text",
                              attrs: {
                                color:
                                  _vm.filterToggle === 1
                                    ? "#0c67a5"
                                    : "#e6e6e6",
                                height: "38"
                              }
                            },
                            [_c("small", [_vm._v("Feature")])]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      class: _vm.featureView ? "pl-0" : "",
                      attrs: { cols: "3" }
                    },
                    [
                      _vm.filterToggle === 0
                        ? _c("v-text-field", {
                            class: _vm.featureView ? "feature" : "mt-6",
                            attrs: {
                              label: _vm.featureView
                                ? "Filter Features"
                                : "Filter Items",
                              "prepend-inner-icon": "mdi-filter-outline",
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              clearable: "",
                              "clear-icon-cb": "clearFeatureSearch"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        : _c("v-autocomplete", {
                            class: _vm.featureView ? "feature" : "mt-6",
                            attrs: {
                              "auto-select-first": "",
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              items: _vm.featureTypes,
                              "prepend-inner-icon": "mdi-filter-outline",
                              label: "Filter Features",
                              "item-text": "description",
                              clearable: "",
                              "item-value": "description",
                              autocomplete: "off",
                              "menu-props": {
                                bottom: true,
                                offsetY: true,
                                maxHeight: 220
                              }
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c("v-data-table", {
                staticClass: "table",
                attrs: {
                  "custom-filter": _vm.featureView
                    ? _vm.filterFeatures
                    : _vm.filterItems,
                  expanded: _vm.expanded,
                  "footer-props": { "items-per-page-options": _vm.pageSizes },
                  headers: _vm.headers,
                  height: _vm.tableSize,
                  "item-key": _vm.featureView ? "featureId" : "adDetailId",
                  "items-per-page": _vm.pageSize,
                  items: _vm.items,
                  "loading-text": _vm.featureView
                    ? "Loading Features...Please Wait"
                    : "Loading Feature Items...Please Wait",
                  loading: _vm.loading,
                  "no-data-text": _vm.featureView
                    ? "No Features to Display"
                    : "No Items to Display",
                  "no-results-text": _vm.featureView
                    ? "No Matching Features Found"
                    : "No Matching Feature Items Found",
                  search: _vm.search,
                  "sort-by": _vm.tableSort,
                  dense: "",
                  "fixed-header": "",
                  "group-by": _vm.featureView ? "adName" : "groupHeader",
                  "loader-height": "2",
                  "show-select": ""
                },
                on: {
                  "update:expanded": function($event) {
                    _vm.expanded = $event
                  },
                  "update:items": function($event) {
                    _vm.items = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "group.header",
                      fn: function(ref) {
                        var group = ref.group
                        var isOpen = ref.isOpen
                        var toggle = ref.toggle
                        var items = ref.items
                        var headers = ref.headers
                        return [
                          _vm.featureView
                            ? _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      ref: group,
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        "data-open": isOpen
                                      },
                                      on: { click: toggle }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            isOpen
                                              ? "mdi-chevron-up"
                                              : "mdi-chevron-down"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _c(
                                      "b",
                                      { staticClass: "primary--text px-2" },
                                      [_vm._v(_vm._s(group))]
                                    ),
                                    _vm._v(
                                      "- " +
                                        _vm._s(
                                          _vm.search
                                            ? "Filtered Results:"
                                            : "Total Features:"
                                        ) +
                                        " " +
                                        _vm._s(items.length) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            : !_vm.featureView && _vm.selectedFeatures.length
                            ? _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      ref: group,
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        "data-open": isOpen
                                      },
                                      on: { click: toggle }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            isOpen
                                              ? "mdi-chevron-up"
                                              : "mdi-chevron-down"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _c(
                                      "b",
                                      { staticClass: "primary--text px-2" },
                                      [_vm._v(_vm._s(group))]
                                    ),
                                    _vm._v(" - "),
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.search
                                            ? "Filtered Results:"
                                            : "Total Items:"
                                        ) +
                                          " " +
                                          _vm._s(items.length)
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "expanded-item",
                      fn: function(ref) {
                        var headers = ref.headers
                        var item = ref.item
                        return [
                          _c("td"),
                          _vm.featureView
                            ? _c(
                                "td",
                                {
                                  staticClass: "itemDetails",
                                  attrs: { colspan: headers.length }
                                },
                                [
                                  _c("strong", [_vm._v("General Notes: ")]),
                                  item.featNotes
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.featNotes))
                                      ])
                                    : _c("span", { staticClass: "red--text" }, [
                                        _vm._v("N/A")
                                      ])
                                ]
                              )
                            : !_vm.featureView && item
                            ? _c(
                                "td",
                                {
                                  staticClass: "itemDetails",
                                  attrs: { colspan: headers.length }
                                },
                                [
                                  _c("strong", [_vm._v("Cost:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatCurrency(item.item.cost))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("AMAP:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.formatCurrency(item.AMAP))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("EBA:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.formatCurrency(item.EBA))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("Ad BB:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.formatCurrency(item.adBB))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("Ad Scan:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatCurrency(item.adScan))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("ePay:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.formatCurrency(item.ePay))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("Compete:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatCurrency(item.compete))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("PCAF:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.formatCurrency(item.PCAF))
                                  ),
                                  _c("v-divider", {
                                    staticClass: "ml-4 mr-4",
                                    attrs: { vertical: "" }
                                  }),
                                  _c("strong", [_vm._v("Identifier:")]),
                                  _vm._v(" " + _vm._s(item.identifier) + " ")
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.lumpSum",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticClass: "noWrap" }, [
                            _vm._v(_vm._s(_vm.formatCurrency(item.lumpSum)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.retail",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticClass: "noWrap" }, [
                            _vm._v(
                              _vm._s(item.retCt) +
                                " @ " +
                                _vm._s(_vm.formatCurrency(item.retail))
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            { staticClass: "noWrap" },
                            [
                              _vm.featureView
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.getFeatureItems(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "noWrap" },
                                                      [
                                                        _vm._v(
                                                          "mdi-playlist-edit"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", { staticClass: "mr-4" }, [
                                        _vm._v("Feature Items")
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.selected.length > 0,
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.featureView
                                                        ? _vm.setComponent(
                                                            "Feature",
                                                            item
                                                          )
                                                        : _vm.setComponent(
                                                            "FeatureItemEdit",
                                                            item
                                                          )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "noWrap mx-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-square-edit-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v("Edit")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.selected.length > 0,
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.featureView
                                                        ? _vm.setComponent(
                                                            "FeatureCopy",
                                                            item
                                                          )
                                                        : _vm.setComponent(
                                                            "FeatureItemCopy",
                                                            item
                                                          )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    { staticClass: "noWrap" },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-content-duplicate "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v("Copy | Move")])]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              }),
              _c("v-divider"),
              _c(
                "v-toolbar",
                {
                  staticClass: "mt-2",
                  attrs: { dense: "", flat: "", color: "#fafafa" }
                },
                [
                  _vm.selected.length
                    ? _c(
                        "v-menu",
                        {
                          attrs: { top: "", "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            height: "40",
                                            color: "#E0E0E0"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " Selected (" +
                                            _vm._s(_vm.selected.length) +
                                            ") "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            894457539
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm.featureView && _vm.selected.length
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass: "ml-1",
                                      staticStyle: {
                                        color: "#F9A825 !important"
                                      },
                                      on: { click: _vm.viewAllItems }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _vm._v(" View Items"),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                right: "",
                                                color: "#F9A825"
                                              }
                                            },
                                            [_vm._v("mdi-eye-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { color: "#00b24a !important" },
                                  on: {
                                    click: function($event) {
                                      _vm.featureView
                                        ? _vm.setComponent("Feature")
                                        : _vm.setComponent("FeatureItemEdit")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v("Update"),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { right: "", color: "#00b24a" }
                                        },
                                        [_vm._v("mdi-update")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticStyle: { color: "#d32f2f !important" },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirm(
                                        _vm.featureView
                                          ? "Feature"
                                          : "Feature Item"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(" Remove"),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { right: "", color: "#d32f2f" }
                                        },
                                        [_vm._v("mdi-playlist-remove")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        disabled: _vm.selected.length > 0,
                        color: "#0c67a5",
                        height: "40"
                      },
                      on: {
                        click: function($event) {
                          _vm.featureView
                            ? _vm.setComponent("Feature")
                            : _vm.setComponent("FeatureItemAdd")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " Add " +
                          _vm._s(_vm.featureView ? "Feature" : "Items") +
                          " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-plus")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(_vm.component, {
        key: _vm.componentKey,
        tag: "Component",
        attrs: {
          value: _vm.modal,
          selectedAds: _vm.selectedAds,
          selected: _vm.selected,
          isBulkEdit: _vm.isBulkEdit,
          selectedFeature: _vm.selectedFeature,
          selectedFeatures: _vm.selectedFeatures,
          selectedItem: _vm.selectedItem,
          getAdFeatures: _vm.getAdFeatures,
          getFeatureItems: _vm.getFeatureItems,
          viewAllItems: _vm.viewAllItems
        },
        on: {
          "update:value": function($event) {
            _vm.modal = $event
          },
          selectFeature: function($event) {
            _vm.selectedFeature = $event
          }
        }
      }),
      _c("ConfirmDialog", {
        attrs: {
          value: _vm.confirmDialog,
          selected: _vm.selected,
          action: "Delete",
          type: _vm.type,
          remove: _vm.remove
        },
        on: {
          "update:value": function($event) {
            _vm.confirmDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }