<template>
  <v-dialog :value="value" class="dialog" persistent width="1500" fullscreen>
    <v-card color=#fafafa>
      <v-container fluid class="ma-0">
        <v-toolbar dark flat color="#1C3144">
          <v-btn icon dark @click="$emit('update:value', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{isFeatureSelected ? `${selectedFeature.adName} - ${selectedFeature.featureType.name}` : 'Ad Feature Management'}}
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar color="#fafafa">
          <v-btn v-if="!featureView" color="#0c67a5" text small @click="getAdFeatures">
            <v-icon left>mdi-chevron-left</v-icon>
            Back to Features
          </v-btn>
          <v-spacer/>
          <v-btn-toggle v-if="featureView || selectedFeatures.length" mandatory>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn :color="!isExpanded ? 'green' : 'red'" class="white--text" v-on="on" height="40" :disabled="!isExpanded" @click="isExpanded=false, collapseAll()">
                  <v-icon color="white">mdi-collapse-all-outline</v-icon>
                </v-btn>
              </template>
              <span>Collapse Rows</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn :color="isExpanded ? 'red' : 'green'" class="white--text" v-on="on" height="40" :disabled="isExpanded" @click="isExpanded=true, expandAll()">
                  <v-icon color="white">mdi-expand-all-outline</v-icon>
                </v-btn>
              </template>
              <span>Expand Rows</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-col v-if="!featureView" cols="2">
            <v-select class="mt-6" outlined dense background-color="#fff" label="Sort By Asc"
              :items="sortOptions" :menu-props="{ bottom: true, offsetY: true }" item-text="label" item-value="value"
              @change="changeSort" prepend-inner-icon="mdi-sort-ascending" />
          </v-col>
          <v-divider vertical inset class="mx-4" />
          <v-btn-toggle v-if="featureView" dense v-model="filterToggle" mandatory class="btnGroup">
            <v-btn :color="filterToggle === 0 ? '#0c67a5' : '#e6e6e6'" :class="filterToggle === 0 ? 'white--text' : 'black--text'"
              height="38" style="border-top-left-radius:3px;border-bottom-left-radius:3px;">
              <small>Manufacturer/Description</small>
            </v-btn>
            <v-btn :color="filterToggle === 1 ? '#0c67a5' : '#e6e6e6'" :class="filterToggle === 1 ? 'white--text' : 'black--text'"
              height="38">
              <small>Feature</small>
            </v-btn>
          </v-btn-toggle>
          <v-col cols="3" :class="featureView ? 'pl-0' : ''">
            <v-text-field v-if="filterToggle === 0" :label="featureView ? 'Filter Features' : 'Filter Items'"
              prepend-inner-icon="mdi-filter-outline" outlined dense :class="featureView ? 'feature' : 'mt-6'"
              background-color="#fff" v-model="search" clearable clear-icon-cb="clearFeatureSearch">
            </v-text-field>
            <v-autocomplete v-else auto-select-first background-color="#fff" dense outlined
              :class="featureView ? 'feature' : 'mt-6'" :items="featureTypes" prepend-inner-icon="mdi-filter-outline"
              label="Filter Features" item-text="description" clearable item-value="description" v-model="search"
              autocomplete="off" :menu-props="{ bottom: true, offsetY: true, maxHeight: 220 }">
            </v-autocomplete>
          </v-col>
        </v-toolbar>
        <v-divider />

        <v-data-table
          :custom-filter="featureView ? filterFeatures : filterItems"
          :expanded.sync="expanded"
          :footer-props="{'items-per-page-options': pageSizes}"
          :headers="headers"
          :height="tableSize"
          :item-key="featureView ? 'featureId' : 'adDetailId'"
          :items-per-page="pageSize"
          :items.sync="items"
          :loading-text="featureView ? 'Loading Features...Please Wait' : 'Loading Feature Items...Please Wait'"
          :loading="loading"
          :no-data-text="featureView ? 'No Features to Display' : 'No Items to Display'"
          :no-results-text="featureView ? 'No Matching Features Found' : 'No Matching Feature Items Found'"
          :search="search"
          :sort-by="tableSort"
          class="table"
          dense
          fixed-header
          :group-by="featureView ? 'adName' : 'groupHeader'"
          loader-height="2"
          show-select
          v-model="selected"
        >

          <template v-slot:[`group.header`]="{ group, isOpen, toggle, items, headers }">
            <td v-if="featureView" :colspan="headers.length">
              <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                <v-icon>{{isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
              </v-btn>
              <span>
                <b class="primary--text px-2">{{group}}</b>-
                {{search ? 'Filtered Results:': 'Total Features:'}} {{items.length}}
              </span>
            </td>
            <td v-else-if="!featureView && selectedFeatures.length" :colspan="headers.length">
              <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                <v-icon>{{isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
              </v-btn>
              <span>
                <b class="primary--text px-2">{{group}}</b> -
                <em>{{search ? 'Filtered Results:': 'Total Items:'}} {{items.length}}</em>
              </span>
            </td>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td></td>
            <td v-if="featureView" class="itemDetails" :colspan="headers.length">
              <strong>General Notes: </strong>
              <span v-if="item.featNotes">{{item.featNotes}}</span>
              <span v-else class="red--text">N/A</span>
            </td>
            <td v-else-if="!featureView && item" class="itemDetails" :colspan="headers.length">
              <strong>Cost:</strong> {{formatCurrency(item.item.cost)}}<v-divider class="ml-4 mr-4" vertical>
              </v-divider>
              <strong>AMAP:</strong> {{formatCurrency(item.AMAP)}}<v-divider class="ml-4 mr-4" vertical></v-divider>
              <strong>EBA:</strong> {{formatCurrency(item.EBA)}}<v-divider class="ml-4 mr-4" vertical></v-divider>
              <strong>Ad BB:</strong> {{formatCurrency(item.adBB)}}<v-divider class="ml-4 mr-4" vertical></v-divider>
              <strong>Ad Scan:</strong> {{formatCurrency(item.adScan)}}<v-divider class="ml-4 mr-4" vertical>
              </v-divider>
              <strong>ePay:</strong> {{formatCurrency(item.ePay)}}<v-divider class="ml-4 mr-4" vertical></v-divider>
              <strong>Compete:</strong> {{formatCurrency(item.compete)}}<v-divider class="ml-4 mr-4" vertical>
              </v-divider>
              <strong>PCAF:</strong> {{formatCurrency(item.PCAF)}}<v-divider class="ml-4 mr-4" vertical></v-divider>
              <strong>Identifier:</strong> {{item.identifier}}
            </td>
          </template>

          <template v-slot:[`item.lumpSum`]="{ item }">
            <span class="noWrap">{{formatCurrency(item.lumpSum)}}</span>
          </template>

          <template v-slot:[`item.retail`]="{ item }">
            <span class="noWrap">{{item.retCt}} @ {{formatCurrency(item.retail)}}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <span class="noWrap">
              <v-tooltip v-if="featureView" top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" small icon @click="getFeatureItems(item)">
                    <v-icon class="noWrap">mdi-playlist-edit</v-icon>
                  </v-btn>
                </template>
                <span class="mr-4">Feature Items</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="selected.length > 0" v-on="on" small icon @click="featureView ? setComponent('Feature', item) : setComponent('FeatureItemEdit', item)">
                    <v-icon class="noWrap mx-2">
                      mdi-square-edit-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="selected.length > 0" v-on="on" small icon @click="featureView ? setComponent('FeatureCopy', item) : setComponent('FeatureItemCopy', item)">
                    <v-icon v-on="on" class="noWrap">
                      mdi-content-duplicate
                    </v-icon>
                  </v-btn>
                </template>
                <span>Copy | Move</span>
              </v-tooltip>
            </span>
          </template>

        </v-data-table>

        <v-divider />
        <v-toolbar dense flat color="#fafafa" class="mt-2">
          <v-menu v-if="selected.length" top offset-y>
            <template v-slot:activator="{ on }">
              <v-btn height="40" color="#E0E0E0" v-on="on">
                Selected ({{selected.length}})
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="featureView && selected.length" class="ml-1" style="color:#F9A825 !important;"
                @click="viewAllItems">
                <v-list-item-title>
                  View Items<v-icon right color="#F9A825">mdi-eye-outline</v-icon>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="ml-1" style="color:#00b24a !important;"
                @click="featureView ? setComponent('Feature') : setComponent('FeatureItemEdit')">
                <v-list-item-title>Update<v-icon right color="#00b24a">mdi-update</v-icon>
                </v-list-item-title>
              </v-list-item>
              <v-list-item style="color:#d32f2f !important;" @click="confirm(featureView ? 'Feature' : 'Feature Item')">
                <v-list-item-title>
                  Remove<v-icon right color="#d32f2f">mdi-playlist-remove</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer />
          <v-btn :disabled="selected.length > 0" class="white--text" color="#0c67a5" height="40"
            @click="featureView ? setComponent('Feature') : setComponent('FeatureItemAdd')">
            Add {{featureView ? 'Feature' : 'Items' }}
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
    </v-card>

    <Component :key="componentKey" :is="component" :value.sync="modal" :selectedAds="selectedAds" :selected="selected"
      :isBulkEdit="isBulkEdit" :selectedFeature="selectedFeature" :selectedFeatures="selectedFeatures" :selectedItem="selectedItem"
      :getAdFeatures="getAdFeatures" :getFeatureItems="getFeatureItems" :viewAllItems="viewAllItems" @selectFeature="selectedFeature=$event"/>

    <ConfirmDialog :value.sync="confirmDialog" :selected="selected" :action="'Delete'" :type="type" :remove="remove"/>

  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'

export default {
  name: 'FeatureManagement',

  props: ['value', 'selectedAds'],

  mixins: [tableSettings, notification],

  components: {
    Feature: () => import('@/components/ads/feature'),
    FeatureCopy: () => import('@/components/ads/feature-copy'),
    FeatureItemAdd: () => import('@/components/ads/feature-item-add'),
    FeatureItemEdit: () => import('@/components/ads/feature-item-edit'),
    FeatureItemCopy: () => import('@/components/ads/feature-item-copy'),
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  data () {
    return {
      componentKey: 0,
      isExpanded: true,
      component: '',
      adDate: '',
      adGroup: '',
      clipboard: {},
      confirmDialog: false,
      expanded: [],
      featureCopy: {},
      featureSearch: '',
      featureView: true,
      featureTypes: [],
      filterOption: this.filterFeatures,
      filterToggle: 0,
      loading: true,
      item: {},
      itemKey: 'featureId',
      items: [],
      modal: false,
      pageSize: -1,
      pageSizes: [-1, 500, 1000],
      search: '',
      selected: [],
      selectedFeature: {},
      selectedFeatures: [],
      selectedItem: {},
      tableSort: 'featureId',
      type: ''
    }
  },

  computed: {
    headers () {
      if (this.featureView) {
        return [
          { text: 'Ad Name', align: 'left', sortable: true, filterable: true, value: 'adName', class: 'black--text' },
          { text: 'Feature Type', align: 'left', sortable: true, filterable: true, value: 'featureType.name', class: 'black--text' },
          { text: 'Brand/Combo', align: 'left', sortable: true, filterable: true, value: 'brandCombo', class: 'black--text' },
          { text: 'Manufacturer', align: 'left', sortable: false, filterable: true, value: 'manufacturer.description', class: 'black--text' },
          { text: 'Dimensions', align: 'left', sortable: true, filterable: false, value: 'featDesc4', class: 'black--text' },
          { text: 'Lump Sum', align: 'left', sortable: true, filterable: false, value: 'lumpSum', class: 'black--text' },
          { text: 'Ad Retail', align: 'left', sortable: true, filterable: false, value: 'retail', class: 'black--text' },
          { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action', class: 'black--text' }
        ]
      } else {
        return [
          { text: 'Order Code', align: 'left', sortable: true, filterable: true, value: 'item.orderCode', class: 'black--text' },
          { text: 'Pack', align: 'left', sortable: true, filterable: true, value: 'item.pack', class: 'black--text' },
          { text: 'Size', align: 'left', sortable: true, filterable: true, value: 'item.size', class: 'black--text' },
          { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'item.UPC', class: 'black--text' },
          { text: 'Item Description', align: 'left', sortable: true, filterable: true, value: 'item.description', class: 'black--text' },
          { text: 'Contract ID', align: 'center', sortable: true, filterable: true, value: 'contractId', class: 'black--text' },
          { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action', class: 'black--text' }
        ]
      }
    },

    linkedGroupHeaders () {
      return [
        { text: 'Order Code', align: 'center', sortable: true, filterable: true, value: 'orderCode', class: 'black--text' },
        { text: 'Pack', align: 'left', sortable: true, filterable: true, value: 'pack', class: 'black--text' },
        { text: 'Size', align: 'left', sortable: true, filterable: true, value: 'size', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'UPC', class: 'black--text' },
        { text: 'Item Description', align: 'left', sortable: true, filterable: true, value: 'description', class: 'black--text' },
        { text: 'Cost', align: 'left', sortable: true, filterable: true, value: 'caseCost', class: 'black--text' },
        { text: '', align: 'left', sortable: false, filterable: false, value: '', class: 'black--text' }
      ]
    },

    sortOptions () {
      return [
        { label: 'Cost', value: 'item.cost' },
        { label: 'Ad BB', value: 'adBB' },
        { label: 'Ad Scan', value: 'adScan' },
        { label: 'ePay', value: 'ePay' }
      ]
    },

    isFeatureSelected () {
      return !isEmpty(this.selectedFeature)
    },

    isBulkEdit () {
      return !isEmpty(this.selected)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.loading = true
          this.getAdFeatures()
          this.getFeatureTypes()
          this.search = ''
          localStorage.removeItem('feature_search')
        } else {
          this.items = []
        }
      }
    },

    featureView: {
      handler (value) {
        this.items = []
        this.expanded = []
        this.search = ''
        this.loading = true
        if (value) {
          this.tableSort = 'featureId'
          this.feature = {}
          localStorage.getItem('feature_search') ? this.search = localStorage.getItem('feature_search') : this.search = ''
        } else {
          this.tableSort = 'adDetailId'
        }
      }
    },

    selected: {
      handler (value) {
        if (this.featureView) {
          this.selectedFeatures = value
        }
      }
    },

    search: {
      handler () {
        if (this.featureView) {
          localStorage.setItem('feature_search', this.search)
        }
      }
    },

    modal: {
      handler (value) {
        this.componentKey += 1
        if (!value && this.featureView) {
          this.selected = []
          this.selectedFeature = {}
        }
        if (!value && !this.featureView) {
          this.item = {}
          if (this.selectedFeatures.length > 0) {
            this.selectedFeature = {}
          }
        }
      }
    },

    notification: {
      handler (value) {
        if (!value) {
          this.complete = false
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 255
  },

  methods: {
    async getFeatureTypes () {
      this.$ads.getAdFeatureTypes()
        .then(response => {
          this.featureTypes = response
        })
    },

    async getAdFeatures () {
      const promises = []
      const arr = []

      this.loading = true
      this.featureView = true
      this.selected = []

      this.selectedAds.forEach(ad => {
        promises.push(
          this.$ads.getAdFeatures(ad.adId)
            .then(response => {
              response.forEach(feature => {
                arr.push(feature)
              })
            })
        )
      })

      await Promise.allSettled(promises)

      this.items = arr
      this.$data.expanded = this.$data.items
      this.loading = false
      localStorage.getItem('feature_search') !== null
        ? this.search = localStorage.getItem('feature_search')
        : localStorage.removeItem('feature_search')
    },

    async getFeatureItems (feature) {
      this.selectedFeature = feature
      this.featureView = false
      this.loading = true
      this.selected = []

      await this.$ads.getFeatureItems(feature.featureId)
        .then(response => {
          this.items = response
          this.$data.expanded = this.$data.items
          this.loading = false
        })
    },

    async viewAllItems () {
      this.featureView = false
      var promises = []
      var arr = []
      this.selectedFeature = {}
      this.selected = []
      this.selectedFeatures.forEach(feature => {
        promises.push(
          this.$ads.getFeatureItems(feature.featureId)
            .then(response => {
              response.forEach(featureItem => {
                featureItem.groupHeader = `${feature.adName} | ${feature.brandCombo}`
                arr.push(featureItem)
              })
            })
        )
      })
      await Promise.allSettled(promises)
      this.items = arr
      this.$data.expanded = this.$data.items
      this.loading = false
    },

    async getByItemCode (itemCode, supplier) {
      this.errors = []
      this.linkedGroupItems = []
      await this.$contracts.getByItemCode(itemCode, supplier)
        .then(response => {
          if (response.length > 0 && isEmpty(this.clipboard)) {
            this.item = {
              orderCode: response[0].orderCode,
              itemCode: response[0].itemCode,
              pack: response[0].pack,
              size: response[0].size,
              UPC: response[0].UPC,
              description: response[0].description,
              cost: response[0].caseCost,
              identifier: 'ADVRD1',
              AMAP: '0.00',
              adBB: '0.00',
              adScan: '0.00',
              ePay: '0.00',
              EBA: '0.00',
              compete: '0.00',
              PCAF: '0.00'
            }
          } else {
            this.item = {
              orderCode: response[0].orderCode,
              itemCode: response[0].itemCode,
              pack: response[0].pack,
              size: response[0].size,
              UPC: response[0].UPC,
              description: response[0].description,
              cost: this.clipboard.item.cost,
              identifier: this.clipboard.identifier,
              AMAP: this.clipboard.AMAP,
              adBB: this.clipboard.adBB,
              adScan: this.clipboard.adScan,
              ePay: this.clipboard.ePay,
              EBA: this.clipboard.EBA,
              compete: this.clipboard.compete,
              PCAF: this.clipboard.PCAF
            }
          }
          this.getLinkedGroups(itemCode, supplier)
        }).catch(err => {
          this.errors.push(err.response.data.msg)
          this.errorModal = true
        })
    },

    async remove () {
      const promises = []
      const errors = []

      this.showLoader('Deleting Features', true)
      if (this.featureView) {
        this.selected.forEach(feature => {
          promises.push(
            this.$ads.deleteAdFeature(feature)
              .then().catch(() => {
                errors.push(`${feature.featDesc} ${feature.featDesc2} Not Deleted`)
              })
          )
        })

        await Promise.allSettled(promises)

        if (errors.length) {
          this.showErrors(errors)
        } else {
          this.notify('success', `${this.selected.length > 1 ? 'Features' : 'Feature'} Successfully Deleted`)
        }

        this.getAdFeatures()
      } else {
        this.selected.forEach(featureItem => {
          promises.push(
            this.$ads.deleteFeatureItem(featureItem)
              .then().catch(() => {
                errors.push(`${featureItem.item.orderCode} - ${featureItem.item.description} Not Deleted`)
              })
          )
        })
        await Promise.allSettled(promises)
        if (errors.length) {
          this.showErrors(errors)
        } else {
          this.notify('success', `${this.selected.length > 1 ? 'Feature Items' : 'Feature Item'} Successfully Deleted`)
        }
        if (Object.keys(this.selectedFeature).length > 0) {
          this.getFeatureItems(this.selectedFeature)
        } else {
          this.viewAllItems()
        }
      }
    },

    collapseAll () {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click()
        }
      })
    },

    expandAll () {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && !this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click()
        }
      })
    },

    setComponent (component, selection) {
      if (selection) this.featureView ? this.selectedFeature = selection : this.selectedItem = selection
      // this.selected = []
      this.component = component
      this.modal = true
    },

    confirm (type) {
      this.type = type
      this.confirmDialog = true
    },

    clearFeatureSearch () {
      localStorage.removeItem('feature_search')
    },

    copyToClipboard () {
      // this.clipboard = { ...item }
      this.text = 'Copied'
      this.copied = true
      setTimeout(() => { this.copied = false }, 2000)
    },

    clearClipboard () {
      this.clipboard = {}
      this.getByItemCode(this.item.OrderCode)
    },

    changeSort (value) {
      this.tableSort = value
    },

    filterFeatures (value, search) {
      if (this.filterToggle === 1) {
        if (!value) return ''
        if (value === search || value.toLowerCase() === search.toLowerCase()) {
          return value
        }
      } else {
        if (!value) return ''
        return value.toString().toLowerCase().indexOf(search) !== -1 || value.toString().indexOf(search) !== -1
      }
    },

    filterItems (value, search) {
      if (!value) return ''
      return value.toString().indexOf(search) !== -1 || value.toString().toLowerCase().indexOf(search) !== -1
    },

    formatCurrency (value) {
      return '$' + parseFloat(value).toFixed(2)
    }
  }
}
</script>

<style scoped>
.feature {
  border-top-left-radius:0px;
  border-bottom-left-radius:0px;
  margin-top:25px;
}

/* .activeFilter {
  color: #133C55 !important;
} */

.btnGroup {
  border-top: .75px solid #9e9e9e;
  border-left: .75px solid #9e9e9e;
  border-bottom: .75px solid #9e9e9e;
  /* margin-bottom: 0.1em; */
  height: 40px;
  border-top-right-radius:0px !important;
  border-bottom-right-radius:0px;
}

.noWrap {
  white-space: nowrap;
}

.table >>> tbody tr.v-data-table__expanded__content {
  background: #eeeeee;
  box-shadow: none;
}
</style>
